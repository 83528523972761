import React from 'react';
import { useEffect, useState } from 'react';
import { If, IntrinsicProps, createComponent } from 'react-commons';

import style from './index.module.scss';
import UAParser from 'ua-parser-js';

interface OrientationOverlayProps extends IntrinsicProps {
}

export default createComponent<OrientationOverlayProps>('OrientationOverlay', { style }, function OrientationOverlay ({ className }) {
  const [ visible, setVisible ] = useState(false);
  
  const matchMedia = () => {
    const parser = new UAParser(window.navigator.userAgent || '');
    const parserResults = parser.getResult();
    const isMobile = parserResults.device.type === 'mobile';

    const query = 'screen and (max-device-width: 1024px) and (orientation: landscape)';
    const mediaQuery = window.matchMedia(query);
    
    if (isMobile && mediaQuery.matches) {
      document.body.classList.add('no-scroll');
      setVisible(true);
    } else {
      document.body.classList.remove('no-scroll');
      setVisible(false);
    }
  };

  useEffect(() => {
    const handler = () => {
      matchMedia();
    };
    window.addEventListener('resize', handler);
    window.addEventListener('orientationchange', handler);

    matchMedia();

    () => {
      window.removeEventListener('resize', handler);
      window.removeEventListener('orientationchange', handler);
    };
  }, []);

  return (
    <>
      {
        If(visible, () => (
          <div className={className}>
            <div>Please rotate your device to portrait mode to view the Shockwave website.</div>
          </div>
        )).EndIf()
      }
    </>
  );
});
