import { App, If, IntrinsicProps, Layout } from 'react-commons';
import { createComponent } from 'react-commons';

import DailyAppHeader from '@/components/DailyAppHeader';
import OrientationOverlay from '@/components/OrientationOverlay';

import style from './index.module.scss';
import { CaptureClickEventContext } from '@/lib/util/captureClickEvent';
import PlaywireAside, { PlaywireAside2 } from '@/components/playwire/PlaywireAside';
import { useAuthStore } from '@/lib/drupal/stores/auth';
import AppFooter from '@/components/AppFooter';

interface FluidLayoutProps extends IntrinsicProps {
  restrictHeight?: boolean
  pageName?: string
  pageEventSettings?: any
  classicColumnWidth?: boolean
  useAdColumn?: boolean
}

const classStates = [
  'restrictHeight',
  'classicColumnWidth',
];

export default createComponent<FluidLayoutProps>('FluidLayout', { style, classStates }, function FluidLayout ({ className, slots }, props) {
  const [ authState ] = useAuthStore();

  return (
    <CaptureClickEventContext.Provider value={{
      page: props.pageName,
      settings: props.pageEventSettings || {},
    }}>
      <Layout className={className}>
        <App.Header>
          {
            If(slots.appHeader, () => (slots.appHeader))
              .Else(() => (<DailyAppHeader />))
              .EndIf()
          }
        </App.Header>

        <App.Main>
          {
            If(props.useAdColumn && !authState.user?.isPremiumUser, () => (
              <aside className='FluidLayout__Aside'>
                <PlaywireAside />
              </aside>
            )).EndIf()
          }
          <div className='FluidLayout__Content'>
            {slots.defaultSlot}
          </div>
          {
            If(props.useAdColumn && !authState.user?.isPremiumUser, () => (
              <aside className='FluidLayout__Aside'>
                <PlaywireAside2 />
              </aside>
            )).EndIf()
          }
        </App.Main>

        {
          If(props.classicColumnWidth, () => (
            <App.Footer>
              <AppFooter />
            </App.Footer>
          )).EndIf()
        }

        <OrientationOverlay />
      </Layout>
    </CaptureClickEventContext.Provider>
  );
});
